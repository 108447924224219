import './Footer.css';

export default function Footer() {
    return (
        <footer className="row bg-secondary text-light text-center">
            <div className="col-12">
                <span className="footer-content">Gretchen Picklesimer Kinney</span>
            </div>
            <div className="col-12">
                <span>Website coded with love by her husband, Kevin</span>
            </div>
        </footer>
    );
}