export const Links = [
  {
    name: 'Home',
    path: '/'
  },
  {
    name: 'Portfolio',
    path: '/portfolio'
  },
  {
    name: 'Projects',
    path: '/projects'
  },
  {
    name: 'Book Blog',
    path: 'https://blog.gretchenpickles.com'
  }
]
